import React, { useRef } from "react";
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import Layout from "../Layout";
import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image";
import * as Markdown from 'react-markdown';
import FullscreenHeader from "../components/Content/FullscreenHeader";
import CapabilitiesListSection from '../components/Content/CapabilitiesListSection';
// import ToolsContent from "../components/Rates/Tools";
import booked from "../images/og-rates.png";

import image from "../images/astro_profile.jpg";
const imageUrl = `https://michaellisboa.com${image}`;

const TeamHero = ({ media, index, content }) => {
    return (
        <div id={`profile_${index}`} style={{ height: "100vh" }} className="uk-height-min-large uk-cover-container uk-overflow-hidden uk-position-relative">
            <BackgroundImage
                Tag="div"
                fluid={media.childImageSharp.fluid}
                preserveStackingContext={true}
                className="uk-background-fixed"
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: `top center`,
                    backgroundAttachment: `fixed`,
                    height: "100%",
                    width: "100%"
                }}>
                <div style={{ backgroundColor: "rgba(29, 38, 54, 0.4)" }} className="uk-position-cover disable-select" />
                <div className="uk-container uk-container-large uk-height-1-1 uk-position-relative uk-light">
                    <div className={`uk-margin-xlarge-top uk-width-3-5@m uk-flex uk-flex-middle uk-height-1-1`}>
                        <div className={`uk-padding uk-text-left uk-margin-xlarge-bottom`} data-uk-parallax="y: 0,200;opacity: 1,0;offset: 100">
                            <Markdown
                                source={content}
                                escapeHtml={false}
                            />
                        </div>
                        {index !== 6 ?
                            <a href={`#profile_${index + 1}`} data-uk-scroll><span className="uk-position-bottom-center uk-margin-large-bottom" data-uk-icon="icon: chevron-down;ratio: 3" /></a>
                            : null}
                    </div>
                </div>
            </BackgroundImage>
        </div>
    );
};

const LogoGrid = ({ logos }) => {

    const logoGrid = logos

    return (
        <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
            <div className="client-grid uk-width-5-6@m uk-grid uk-grid-large uk-flex-center uk-child-width-1-3 uk-child-width-1-6@m"
                data-uk-scrollspy="target: div; cls: uk-animation-slide-bottom-medium; delay: 20">
                {logoGrid.map((logo, i) =>
                    <div key={i}>
                        <Img
                            fluid={logo.localFile ? logo.localFile.childImageSharp.fluid : logo.childImageSharp.fluid}
                            alt={logo.name}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const TheTeam = ({ location, data }) => {

    const description = `Over twenty years in user experience, brand strategy, and technology at work for you.`
    const available = useRef(true)
    const ogImage = `https://michaellisboa.com${booked}`;

    const title = `All About Us`;
    return (
        <Layout location={location}>
            <Helmet>
                <title>{title}</title>
                <meta name="image" content={imageUrl} />
                <meta property="og:image" content={imageUrl} />
                <meta name="twitter:image" content={imageUrl} />
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta name="twitter:description" content={description} />
            </Helmet>

            <FullscreenHeader
                media={data.hero_img}
                content={'<h1 class="h1__xlarge">Worth every<br />damn penny.</h1><p class="uk-text-lead">Twenty-five years of <span class="uk-text-nowrap">brand experience</span> and<br />creative technology <span class="uk-text-nowrap">working for you.</span></p>'}
            />

            {!available.current ?
                <div className="uk-padding-xlarge uk-padding-remove-horiztonal uk-background-astro-darker uk-light uk-flex uk-flex-middle">
                    <div className="uk-container uk-container-expand">
                        <div className="uk-width-1-1 uk-width-4-5@m">
                            <div className="uk-padding-small uk-width-1-1 uk-width-auto@s">
                                <h1 className="text-hero uk-margin-bottom">(429) Too many requests.</h1>
                                <p className="uk-text-lead uk-margin-remove-vertical">
                                    I'm fully booked and won't be taking on new clients through April 2022.
                                </p>
                                <p className="uk-text-lead">
                                    Do you have a really cool project coming up? <a href="#contact-form" className="uk-text-nowrap" data-uk-scroll>
                                        Let's catch up</a> to talk about scheduling for this Spring.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            <CapabilitiesListSection />
            
            <section className="uk-section uk-section-large uk-background-astro-lightest">
                <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                    <div className="uk-width-4-5@m uk-margin-medium-bottom">
                        <div className="uk-padding-small uk-padding-remove-vertical uk-width-3-5@m" data-uk-scrollspy="target: > p, h3; cls: uk-animation-slide-bottom; delay: 100">
                        <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                            <strong>&mdash;</strong> We've been around the block a few times
                        </p>
                        <h3 className="uk-margin-remove-top">
                            <span className="uk-display-block">
                                We've delivered a lot of very cool work for a lot of very cool clients.
                            </span>
                        </h3>
                        </div>
                    </div>
                </div>
                <LogoGrid logos={data.allContentfulImageCollection.nodes[0].images} />
                <p className="uk-width-1-1 uk-text-small uk-text-muted uk-text-center">(and many, many more...)</p>
            </section>
            

            <section className="uk-section uk-section-large uk-background-astro-lighter">
                <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                    <div className="uk-width-4-5@m uk-margin-medium-bottom">
                        <div className="uk-padding-small uk-padding-remove-vertical uk-width-3-5@m" data-uk-scrollspy="target: > p, h3; cls: uk-animation-slide-bottom; delay: 100">
                            <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                                <strong>&mdash;</strong> Tools of our trade
                            </p>
                            <h3 className="uk-margin-remove-top">
                                Full stack Creative Technology <span className="uk-text-nowrap">to deliver</span> experiences your <span className="uk-text-nowrap">customers will love.</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <LogoGrid logos={data.allTools.nodes} />
            </section>

            <FullscreenHeader
                media={data.mypic2.nodes[0]}
                content={'<h6 style="font-weight: 800 !important">Michael &bull; Creative Silver Bullet</h6><h1 class="h1__xlarge uk-margin-remove-vertical">Experience is<br />the teacher<br />of all things.</h1>'}
            />
            <section className="uk-section uk-section-large">
                <div className="uk-container uk-container-expand uk-margin-xlarge uk-flex uk-flex-center">
                    <div className="uk-padding-small uk-width-3-5@m">
                        <p className="uk-text-lead">
                            <span className="uk-dropcap uk-float-left" data-uk-icon="icon: quote-right; ratio: 4.5;" />
                            Originally from New York, by way of San Francisco, Michael launched his first digital agency way back in the early 90's.
                        </p>
                        <p>Famously known as the inventor of #2c2c2b, Michael spent his formative years as part of the renowned <span className="uk-text-nowrap">cage-fighting</span> trio, "Michael, Siegfried &amp; Roy," prior to the German duo departing to pursue feeding White Tigers in Las Vegas.</p>
                        <p>Since then he's led creative, product design, and development teams around the world to solve brand experience and communication problems for the most recognized names in the world including Citibank, Justin Timberlake, the Oakland Raiders, Singapore Airlines, Pepsi, Sony, Microsoft and many, oh-so-many others.</p>
                        <div
                            className="about-profile-content uk-container uk-container-small uk-margin-large">

                            <div className="uk-flex uk-flex-center">
                                <div className="uk-width-4-5@m">
                                    <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                                        <strong>&mdash;</strong> In summary
                                    </p>
                                    <h2 className="h2__large uk-margin">Successfully delivered hundreds of projects.</h2>
                                    <h2 className="h2__large uk-margin">Startup clients raised over <span className="uk-text-nowrap">$260 Million,</span></h2>
                                    <h2 className="h2__large uk-margin">Acquisitions of more than <span className="uk-text-nowrap">$1 Billion.</span></h2>
                                </div>
                            </div>
                        </div>
                        <p>He's held Creative and Brand Experience Director roles in some of the biggest agencies in Ad Land, founded startups and, single-handedly, developed deep-tech AI platforms.</p>
                        <p>Many believe he's a total asshole, but in reality, he is.</p>
                        <p>He's one of those unique people that soaks in information, continues to learn every day, and&mdash;most importantly&mdash;applies that knowledge to invent, execute and deliver world class results.</p>
                    </div>
                </div>
            </section>
            {/* <TeamHero
        media={data.michael}
        index={0}
        content={'<h6 style="font-weight: 800 !important">Michael &bull; Creative Silver Bullet</h6><h1 class="h1__xlarge uk-margin-remove-vertical">If you rollin\' with winners<span style="font-family: Georgia, Arial Black, Maven Pro">.</span></h1><p class="uk-text-lead">Then you rollin\' with us.</p>'} /> */}
            <TeamHero
                media={data.geoferey}
                index={1}
                content={'<h6 style="font-weight: 800 !important">Geoferey &bull; UX/UI Design Lead</h6><h1 class="h1__xlarge uk-margin-remove-vertical">When does milk decide it\'s cheese<span style="font-family: Arial Black, Maven Pro">?</span></h1>'} />
            <TeamHero
                media={data.janet}
                index={2}
                content={'<h6 style="font-weight: 800 !important">Janet &bull; Creative / UI Design</h6><h1 class="h1__xlarge uk-margin-remove-vertical">Mind power is the greatest power<span style="margin-left: -8px; font-family: Georgia, Arial Black, Maven Pro">.</span></h1>'} />
            <TeamHero
                media={data.dinda}
                index={3}
                content={'<h6 style="font-weight: 800 !important">Dinda &bull; UX/UI Designer</h6><h1 class="h1__xlarge uk-margin-remove-vertical">Follow the flow<span style="margin-left: -8px; font-family: Georgia, Arial Black, Maven Pro">.</span></h1><p class="uk-text-lead">Go with the soul.</p>'} />
            <TeamHero
                media={data.kathleen}
                index={4}
                content={'<h6 style="font-weight: 800 !important">Kathleen &bull; UX Design Lead</h6><h1 class="h1__xlarge uk-margin-remove-vertical">When you look into the abyss<span style="font-family: Georgia, Arial Black, Maven Pro">.</span></h1><p class="uk-text-lead">Eventually, it will look back at you.</p>'} />
            <TeamHero
                media={data.migs}
                index={5}
                content={'<h6 style="font-weight: 800 !important">MIGS &bull; UX/UI Designer</h6><h1 class="h1__xlarge uk-margin-remove-vertical">That damn monkey<span style="margin-left: -8px; font-family: Georgia, Arial Black, Maven Pro">.</span></h1><p class="uk-text-lead">Won\'t leave me alone.</p>'} />
            <TeamHero
                media={data.astro}
                index={6}
                content={'<h6 style="font-weight: 800 !important">Astro &bull; Chief of Chill</h6><h1 class="h1__xlarge uk-margin-remove-vertical">Bacon<span style="font-family: Arial Black, Maven Pro, Maven Pro">?</span><br />Squirrel<span style="font-family: Arial Black, Maven Pro, Maven Pro">?</span><br />Ball<span style="font-family: Arial Black, Maven Pro">?</span></h1>'} />
        </Layout>
    )
}

export default TheTeam;


export const teamQuery = graphql`
    fragment teamPhoto on File {
        childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    query teamQuery {
        hero_img: file(relativePath: { eq: "mypic_rates.jpg" }) {
            ...ratesPhoto
          }
        michael: file(relativePath: { eq: "mypic3.jpg" }) {
            name
            ...teamPhoto
          }

          astro: file(relativePath: { eq: "astro_profile.jpg" }) {
            name
            ...teamPhoto
          }
          kathleen: file(relativePath: { eq: "kathleen.jpg" }) {
            name
            ...teamPhoto
          }
          migs: file(relativePath: { eq: "migs.jpg" }) {
            name
            ...teamPhoto
          }
          geoferey: file(relativePath: { eq: "geoferey.jpg" }) {
            name
            ...teamPhoto
          }
          janet: file(relativePath: { eq: "janet.jpg" }) {
            name
            ...teamPhoto
          }
          dinda: file(relativePath: { eq: "dinda.jpg" }) {
            name
            ...teamPhoto
          }

          allContentfulImageCollection(filter: {slug: {eq: "ux-design-clients"}}) {
            nodes {
              slug
              backgroundColor
              displayFormat
              displayWidth
              images {
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 256, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  name
                }
              }
            }
          }
          
          
          mypic2: allFile(filter: {name: {eq: "mypic2"}}) {
            nodes {
              name
              publicURL
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              internal {
                mediaType
              }
            }
          }

          allTools: allFile(filter: {relativeDirectory: {in: "tools_logos"}}) {
            nodes {
              name
              childImageSharp {
                fluid(maxWidth: 200, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
              }
            }
          }

          allClients: allFile(filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "client_logos"}}) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(maxWidth: 200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          
    }`
import React from "react";

const CapabilitiesListSection = () => {
    return (
        <section className="uk-section uk-section-large">
            <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                <div className="uk-width-4-5@m">
                    <div className="uk-padding-small uk-padding-remove-vertical uk-margin-large-bottom" data-uk-scrollspy="target: > p, h3; cls: uk-animation-slide-bottom; delay: 200">
                        <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                            <strong>&mdash;</strong> The things we do
                        </p>
                        <h3 className="uk-margin-remove-top">
                            The things we can<br />do for you.
                        </h3>
                    </div>
                    <div className="uk-grid uk-grid-large uk-grid-row-medium uk-child-width-1-2@s uk-padding-small">
                        <div>
                            <div className="uk-child-width-1-2 uk-grid uk-grid-row-medium">
                                <div data-uk-scrollspy="target: > p, h3; cls: uk-animation-slide-bottom; delay: 100">
                                    <p className="uk-text-xlarge uk-text-bold uk-text-astro-darker uk-margin-remove-vertical">Brand</p>
                                    <p className="uk-text-medium uk-text-astro-darker uk-margin-remove-top uk-margin-medium-bottom">
                                        Strategize &amp; Guide.
                                    </p>
                                    <p>Brand Strategy</p>
                                    <p>Public Relations</p>
                                    <p>Communications</p>
                                    <p>Brand Identity</p>
                                    <p>Content Marketing</p>
                                </div>
                                <div data-uk-scrollspy="target: > p, h3; cls: uk-animation-slide-bottom; delay: 110">
                                    <p className="uk-text-xlarge uk-text-bold uk-text-astro-darker uk-margin-remove-vertical">Experience</p>
                                    <p className="uk-text-medium uk-text-astro-darker uk-margin-remove-top uk-margin-medium-bottom">
                                        Define &amp; Design.
                                    </p>
                                    <p>Business Analysis</p>
                                    <p>User Research</p>
                                    <p>Usability Testing</p>
                                    <p>Service Mapping</p>
                                    <p>Product Design</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-child-width-1-2 uk-grid uk-grid-row-medium">
                                <div data-uk-scrollspy="target: > p, h3; cls: uk-animation-slide-bottom; delay: 120">
                                    <p className="uk-text-xlarge uk-text-bold uk-text-astro-darker uk-margin-remove-vertical">Creative</p>
                                    <p className="uk-text-medium uk-text-astro-darker uk-margin-remove-top uk-margin-medium-bottom">
                                        Ideate &amp; Make.
                                    </p>
                                    <p>Creative Direction</p>
                                    <p>Visual Design</p>
                                    <p>UX/UI Design</p>
                                    <p>Copywriting</p>
                                    <p>Content Development</p>
                                </div>
                                <div data-uk-scrollspy="target: > p, h3; cls: uk-animation-slide-bottom; delay: 130">
                                    <p className="uk-text-xlarge uk-text-bold uk-text-astro-darker uk-margin-remove-vertical">Technology</p>
                                    <p className="uk-text-medium uk-text-astro-darker uk-margin-remove-top uk-margin-medium-bottom">
                                        Advise &amp; Build.
                                    </p>
                                    <p>Technical Direction</p>
                                    <p>UI Development</p>
                                    <p>Full-stack Development</p>
                                    <p>Progressive Web Apps</p>
                                    <p>Web Development</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CapabilitiesListSection;